<template>
  <div id="app">
    <div class="wrapper">
      <!-- Content -->
      <div id="content" class="content">
        <router-view></router-view>
      </div>
    </div>
    <footer id="footer" class="footer">
      <div class="copyright">
        <p class="left small">InnovareTI 2019</p>
        <p class="right small">
          Desenvolvido por
          <a href="https://www.innovareti.com.br/" target="_blank">
            <img src="../../assets/images/logo-innovare-dark.png" alt="Logo" />
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "app",
  data: () => ({
    drawer: null,
    authenticated: false
  }),
  methods: {
    forgotPassword() {
      const self = this;
      const api = this.$store.state.api + "user/recovery";

      axios
        .post(api, {
          email: self.forgot.email
        })
        .then(response => {
          self.$message(
            "Sucesso",
            "Solicitação realizada com sucesso",
            "success"
          );
        })
        .catch(error => {
          self.$message("Erro", error.response.data, "error");
        });
    }
  },
  props: {
    source: String
  },
  mounted() {
    let body = $("body");

    body.addClass("md-skin top-navigation custom-login");
    body.removeClass("fixed nav");
  },
  components: {}
};
</script>

<style scoped>
</style>

